import { EventBusy } from "@mui/icons-material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { useApiUtilsContext } from "../../../providers/api-utils-provider"
import { selectActivityFromCache, selectEventFromCache, selectUserFirstName } from "../../../redux/selectors"
import { greyTextColor } from "../../../styles/style-constants"
import { apiPathAcceptDelegation } from "../../../utils/endpoint-paths"
import { membersRoute } from "../../../utils/page-routes"
import { NotificationEventDetailsList, NotificationListItemBase, NotificationPageBase } from "../notification-type-base"


const getBodyOpener = (name) => `Hey ${name}, `


const ListItem = ({
    notification,
    markAsRead,
    markAsUnread,
    addEventRequest,
    addActivityRequest,
    setSelectedNotification,
    selected
}) => {

    const name = useSelector(selectUserFirstName)
    const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))
    const delegateeName = notification?.metadata?.volunteer_name ?? "..."

    useEffect(() => {
        addEventRequest(notification?.metadata?.event_id)
        addActivityRequest(notification?.metadata?.activity_id)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <NotificationListItemBase
        setSelectedNotification={setSelectedNotification}
        selected={selected}
        notification={notification}
        getBodyText={() => getBodyOpener(name) + `Unfortunately, one of your upcoming events has been cancelled:`}
        markAsRead={markAsRead}
        markAsUnread={markAsUnread}
        image={<EventBusy fontSize="large" htmlColor={greyTextColor} />}
        getBody={() => <NotificationEventDetailsList
            event={event}
        />}
    />

}

const Page = ({ notification, refreshNotifications }) => {
    const name = useSelector(selectUserFirstName)
    const { generateEndpoint, doPut } = useApiUtilsContext()

    const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))
    const activity = useSelector(selectActivityFromCache(notification?.metadata?.activity_id))
    const delegateeName = notification?.metadata?.volunteer_name ?? "..."

    const acceptEndpoint = generateEndpoint(apiPathAcceptDelegation(activity?.id))

    const onAccept = async () => {
        try {
            await doPut({ endpoint: acceptEndpoint, queryParams: { notif_id: notification?.id } })
            refreshNotifications()
        } catch (error) {
            console.error(error)
        }
    }

    return (
      <>
        <NotificationPageBase
          notification={notification}
          image={<EventBusy fontSize="large" htmlColor={greyTextColor} />}
          getBodyOpener={() => getBodyOpener(name)}
          getBodyText={() => `Unfortunately, one of your upcoming events has been cancelled:`}
          getBody={() => (
            <NotificationEventDetailsList
              event={event}
              activity={activity}
              showLocation
              showTime
            />
          )}
          getFooter={() =>
            !notification?.metadata?.is_admin && (
              <span>
                <span>If you have any questions or concerns, please </span>
                <Link to={`${membersRoute}?userTypes=ADMIN`}>
                  Contact A Club Admin
                </Link>
                <span> for more information.</span>
              </span>
            )
          }
        />
      </>
    );

}

const NotificationEventCancelled= {
    ListItem,
    Page
}

export default NotificationEventCancelled