import { Box, Divider, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import BasePageComponent from "../../components/base-page-component"
import SaveCancelStack from "../../components/buttons/save-cancel-stack"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { blueButtonColor } from "../../styles/style-constants"
import { apiPathSettings } from "../../utils/endpoint-paths"
import SwitchTile from "./switch-tile"

const SettingsPage = () => {
    const [settings, setSettings] = useState([])
    const [edited, setEdited] = useState(false)
    const { generateEndpoint, doGet, doPut } = useApiUtilsContext()

    const settingsEndpoint = generateEndpoint(apiPathSettings)

    const setSetting = (setting_key, value) => {
        setEdited(true)
        setSettings({
            ...settings, notificationSettings: { ...settings.notificationSettings, [setting_key]: value }
        })
    };

    const updateSettings = async (settings) => {
        try {
            const response = await doPut({ endpoint: settingsEndpoint, body: settings })
            console.log(response)
        } catch (error) {
            console.error(error)
        }
    }

    const getSettings = async () => {
        try {
            const response = await doGet({ endpoint: settingsEndpoint })
            setSettings(response?.data)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getSettings()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <BasePageComponent pageTitle={"Settings"} width="100%" marginRight="50px" inlineContent={<SaveCancelStack disableSave={!edited} saveAction={() => updateSettings(settings)} cancelAction={() => {
        setEdited(false)
        getSettings()
    }} />}>
        <Box marginX={"50px"} marginTop={"20px"} padding={"10px"} sx={{ background: "white", }} >
            <Stack direction={"column"}>
                <Typography
                    fontWeight={700}
                    textTransform={"uppercase"}
                    fontSize={"14px"}
                    lineHeight={"17px"}
                    letterSpacing={"2px"}
                    width={"100%"}
                    color={blueButtonColor}
                >
                    Notification Settings
                </Typography>

                <Typography
                    fontWeight={700}
                    textTransform={"uppercase"}
                    fontSize={"14px"}
                    lineHeight={"17px"}
                    letterSpacing={"2px"}
                    width={"100%"}
                    paddingBottom={"0.5em"}
                    paddingTop={"2em"}
                >
                    Reminders
                </Typography>
                <Divider />
                <SwitchTile label={"Event Reminders"} value={settings?.notificationSettings?.reminderEvent ?? false} onChange={(event) => {
                    setSetting("reminderEvent", event.target.checked);
                }} />
                <Divider />
                <SwitchTile label={"Activity Reminders"} value={settings?.notificationSettings?.reminderActivity ?? false} onChange={(event) => {
                    setSetting("reminderActivity", event.target.checked);
                }} />
                <Divider />

                <Typography
                    fontWeight={700}
                    textTransform={"uppercase"}
                    fontSize={"14px"}
                    lineHeight={"17px"}
                    letterSpacing={"2px"}
                    width={"100%"}
                    paddingBottom={"0.5em"}
                    paddingTop={"2em"}
                >
                    Events
                </Typography>
                <Divider />
                <SwitchTile label={"New Event Creation"} value={settings?.notificationSettings?.eventCreated ?? false} onChange={(event) => {
                    setSetting("eventCreated", event.target.checked);
                }} />
                <Divider />
                <SwitchTile label={"Event Updates"} value={settings?.notificationSettings?.eventUpdated ?? false} onChange={(event) => {
                    setSetting("eventUpdated", event.target.checked);
                }} />
                <Divider />

                <Typography
                    fontWeight={700}
                    textTransform={"uppercase"}
                    fontSize={"14px"}
                    lineHeight={"17px"}
                    letterSpacing={"2px"}
                    width={"100%"}
                    paddingBottom={"0.5em"}
                    paddingTop={"2em"}
                >
                    Activity Delegations
                </Typography>
                <Divider />
                <SwitchTile label={"Delegations from Club Admins"} value={settings?.notificationSettings?.delegationAdmin ?? false} onChange={(event) => {
                    setSetting("delegationAdmin", event.target.checked);
                }} />
                <Divider />
                <SwitchTile label={"Delegations from Club Members"} value={settings?.notificationSettings?.delegationMember ?? false} onChange={(event) => {
                    setSetting("delegationMember", event.target.checked);
                }} />
                <Divider />
            </Stack></Box>

    </BasePageComponent >

}

export default SettingsPage