import { equals, find, head, map, reject } from "ramda"
import BasePageComponent from "../../components/base-page-component"
import CardContainer from "../../components/card-container"
import { Box, Button, Dialog, DialogActions, DialogContentText, DialogTitle, Grid, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import SaveCancelStack from "../../components/buttons/save-cancel-stack"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { apiPathArchiveClubMember, apiPathClubTeamsList, apiPathDeleteQual, apiPathEnableClubMember, apiPathGetClubMember, apiPathGetMemberQuals, apiPathGetMemberTeams, apiPathGetUser, apiPathPutUser } from "../../utils/endpoint-paths"
import PhotoBox from "../../components/photo-box-component"
import FieldLabel from "../../components/fields/field-label"
import DropdownField from "../../components/fields/dropdown-field"
import FormField from "../../components/fields/form-field"
import { blueButtonColor, fieldWidth, statusColors } from "../../styles/style-constants"
import GenderField from "../../components/fields/gender-field"
import DatePickerField from "../../components/fields/date-picker-field"
import { useSelector } from "react-redux"
import { selectCurrentClubId, selectEditMemberCache, selectEditMemberCacheInUse, selectUserUuid } from "../../redux/selectors"
import { memberTypeDropdownOptions, statusActive, statusInactive } from "../../utils/constants"
import { useDispatch } from "react-redux";
import dayjs from "dayjs"
import { clearEditMembersCache, setUserDetails, updateEditMembersCache } from "../../redux/app-slice"
import { useIsAdmin } from "../../utils/user-hooks"
import MultilineField from "../../components/fields/multiline-field"
import AccessNeedsField from "../../components/fields/access-needs-field"
import IndigenousField from "../../components/fields/indigenous-field"
import BooleanField from "../../components/fields/boolean-field"
import FormTableComponent from "../../components/form-table-component"
import isAllMembersTeam from "../../utils/helper-functions/is-all-members-team"
import GoogleMapsField from "../../components/fields/google-maps-field"
import { dashboardRoute, membersRoute, qualRoute } from "../../utils/page-routes"
import { columnTypeDocument, columnTypeExpiry } from "../../components/table-component"
import useDoNavigate from "../../utils/do-navigate"
import { useSearchParams } from "react-router-dom"
import StatusCircleComponent from "../../components/status-circle-component"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import BaseDialog from "../../components/dialog-component"
import ButtonComponent from "../../components/button-component"
import CloseIcon from '@mui/icons-material/Close';
import { redButtonColor } from "../../styles/style-constants"
import { greenButtonColor } from "../../styles/style-constants"
import { ThumbUp } from "@mui/icons-material"

const EditMemberPage = () => {

    const rowSpacing = 5

    const { generateEndpoint, doGet, doPut, uploadPfp, doDelete, doPost } = useApiUtilsContext()
    const isAdmin = useIsAdmin()
    const dispatch = useDispatch()

    const currentClubId = useSelector(selectCurrentClubId)
    const editCacheInUse = useSelector(selectEditMemberCacheInUse)
    const editCache = useSelector(selectEditMemberCache)

    const dispatchUserDetails = userDetails => dispatch(setUserDetails(userDetails))
    const dispatchEditMembersCache = cache => dispatch(updateEditMembersCache(cache))
    const dispatchClearEditMembersCache = () => dispatch(clearEditMembersCache())
    const doNavigate = useDoNavigate()

    // eslint-disable-next-line no-unused-vars
    const [searchParams, _] = useSearchParams()
    const memberUuid = searchParams.get("target")

    const userUuid = useSelector(selectUserUuid)
    const isEditingSelf = equals(memberUuid)(userUuid)

    const [userEmail, setUserEmail] = useState()
    const [userFirstName, setUserFirstName] = useState()
    const [userLastName, setUserLastName] = useState()
    const [userPreferredName, setUserPreferredName] = useState()
    const [userAddress, setUserAddress] = useState()
    const [userContactNumber, setUserContactNumber] = useState()
    const [userGender, setUserGender] = useState()
    const [userDateOfBirth, setUserDateOfBirth] = useState()
    const [userEmergencyContactName, setUserEmergencyContactName] = useState()
    const [userEmergencyContactNumber, setUserEmergencyContactNumber] = useState()
    const [userRole, setUserRole] = useState()
    const [userAccessNeeds, setUserAccessNeeds] = useState([])
    const [userIndigenous, setUserIndigenous] = useState()
    const [userAdditionalDetails, setUserAdditionalDetails] = useState("")
    const [userAccessNeedsPrivate, setUserAccessNeedsPrivate] = useState(true)
    const [userJoined, setUserJoined] = useState()
    const [clubMemberStatus, setClubMemberStatus] = useState()
    const [localClubMemberStatus, setLocalClubMemberStatus] = useState()
    const [userImageFormData, setUserImageFormData] = useState()
    const [userImageId, setUserImageId] = useState()
    const [allTeams, setAllTeams] = useState([])
    const [qualList, setQualList] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [deletingQual, setDeletingQual] = useState()
    const [memberId, setMemberId] = useState()
    const [clubMemberId, setClubMemberId] = useState();
    const [userTeams, setUserTeams] = useState([])

    const [snackbarLoading, setSnackbarLoading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
    const [isArchiveLoading, setIsArchiveLoading] = useState(false);

    const userFullName = userFirstName && userLastName ? `${userFirstName} ${userLastName}` : "New Member"

    const getMemberEndpoint = generateEndpoint(apiPathGetUser(memberUuid))
    const updateUserEndpoint = generateEndpoint(apiPathPutUser(memberUuid))


    const canEdit = isEditingSelf || isAdmin
    const accessNeedsVisible = isEditingSelf || !userAccessNeedsPrivate

    const isMenuOpened = Boolean(anchorEl);

    const onClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onCloseMenu = () => {
        setAnchorEl(null);
    };

    const mapTeams = (team) => {
        return {
            label: team?.details?.name,
            value: team?.id
        }
    }

    const mapQuals = (qual) => {
        return {
            id: qual?.id,
            type: qual?.type?.details?.name,
            uploadId: qual?.uploadId,
            expiry: qual?.expiry,
            needsCert: qual?.type?.needsCertification,
            expired: (qual?.type?.needsCertification ?? false) ? dayjs(qual?.expiry).isBefore(dayjs()) : false
        }
    }

    const handleClose = () => {
        setDialogOpen(false)
        setDeletingQual()
    }

    const deleteQual = async () => {

        try {
            const endpoint = generateEndpoint(apiPathDeleteQual(deletingQual))

            await doDelete({ endpoint })


            const getQualsEndpoint = generateEndpoint(apiPathGetMemberQuals(memberId))
            const qualsResponse = await doGet({ endpoint: getQualsEndpoint })

            setQualList(qualsResponse?.data ?? [])
        } catch (error) {
            console.error(error)
        }

        setDeletingQual(false)
        setDialogOpen(false)
    }

    const mappedQuals = map(mapQuals)(qualList)

    const mappedTeams = map(mapTeams)(reject(isAllMembersTeam)(allTeams))

    const getTeamById = (id) => find((team) => equals(team?.id)(id))(allTeams)

    const onSubmit = async () => {
        setSnackbarLoading(true)
        try {
            let pictureId = userImageId
            if (userImageFormData) {
                pictureId = await uploadPfp(userImageFormData)
            }

            const body = {
                firstName: userFirstName,
                lastName: userLastName,
                mobile: userContactNumber,
                emergencyContact: {
                    name: userEmergencyContactName,
                    mobile: userEmergencyContactNumber
                },
                pictureId,
                email: userEmail,
                gender: userGender,
                preferredName: userPreferredName,
                dateOfBirth: userDateOfBirth && dayjs(userDateOfBirth).format("YYYY-MM-DD"),
                indigenous: userIndigenous,
                location: userAddress ?? {},
                accessNeeds: {
                    needs: map(need => need?.id ?? need)(userAccessNeeds),
                    additionalDetails: userAdditionalDetails,
                    isPrivate: userAccessNeedsPrivate
                }


            }



            await doPut({
                endpoint: updateUserEndpoint,
                body: body
            })



            if (isEditingSelf) {
                dispatchUserDetails(body)
            }
            // TODO: do something with this
        } catch (error) {
            console.error(error)
        }
        setSnackbarLoading(false)
    }

    const handleSaveChanges = async () => {
      if (clubMemberStatus === localClubMemberStatus) {
        onSubmit();
      } else if (localClubMemberStatus === statusInactive) {
        setArchiveDialogOpen(true);
      } else {
        const enableEndpoint = generateEndpoint(apiPathEnableClubMember(memberId));
        doPost({ endpoint: enableEndpoint })
        onSubmit();
      }
    };

    const loadData = async () => {
        try {

            const response = await doGet({ endpoint: getMemberEndpoint })

            const responseData = response?.data

            if (editCacheInUse) {
                setUserEmail(editCache?.userEmail)
                setUserFirstName(editCache?.userFirstName)
                setUserLastName(editCache?.userLastName)
                setUserContactNumber(editCache?.userContactNumber)
                setUserEmergencyContactName(editCache?.userEmergencyContactName)
                setUserEmergencyContactNumber(editCache?.userEmergencyContactNumber)
                setUserPreferredName(editCache?.userPreferredName)
                setUserGender(editCache?.userGender)
                setUserAdditionalDetails(editCache?.userAdditionalDetails)
                setUserAccessNeeds(editCache?.userAccessNeeds)
                setUserIndigenous(editCache?.userIndigenous)
                setUserAddress(editCache?.userAddress)
                setUserAccessNeedsPrivate(editCache?.userAccessNeedsPrivate)
                if (editCache?.userDateOfBirth) {
                    setUserDateOfBirth(dayjs(editCache?.userDateOfBirth))
                }
                setUserImageId(editCache?.userImageId)
                setUserImageFormData(editCache?.userImageFormData)
                dispatchClearEditMembersCache()

            } else {


                setUserEmail(responseData?.email)
                setUserFirstName(responseData?.firstName)
                setUserLastName(responseData?.lastName)
                setUserContactNumber(responseData?.mobile)
                setUserEmergencyContactName(responseData?.emergencyContact?.name)
                setUserEmergencyContactNumber(responseData?.emergencyContact?.mobile)
                setUserPreferredName(responseData?.preferredName)
                setUserGender(responseData?.gender)
                setUserAdditionalDetails(responseData?.accessNeeds?.additionalDetails)
                setUserAccessNeeds(responseData?.accessNeeds?.needs)
                setUserIndigenous(responseData?.indigenous)
                setUserAddress(responseData?.location)
                setUserAccessNeedsPrivate(responseData?.accessNeeds?.isPrivate)
                if (responseData?.dateOfBirth) {
                    setUserDateOfBirth(dayjs(responseData?.dateOfBirth))
                }
                setUserImageId(responseData?.pictureId)
            }


            const memberEndpoint = generateEndpoint(apiPathGetClubMember(currentClubId, responseData?.id))

            const memberResponse = await doGet({ endpoint: memberEndpoint })

            const memberId = memberResponse?.data?.id

            setUserRole(head(memberResponse?.data?.userTypes))
            setClubMemberStatus(memberResponse?.data?.accountStatus !== statusInactive ? statusActive : statusInactive);
            setLocalClubMemberStatus(memberResponse?.data?.accountStatus !== statusInactive ? statusActive : statusInactive);
            setClubMemberId(memberId);
            setUserJoined(dayjs(memberResponse?.data?.created).format("D MMMM YYYY"))

            const teamsEndpoint = generateEndpoint(apiPathClubTeamsList(currentClubId))

            const teamsResponse = await doGet({ endpoint: teamsEndpoint })


            setAllTeams(teamsResponse?.data ?? [])


            if (responseData?.id) {
                const getMemberTeams = generateEndpoint(apiPathGetMemberTeams(memberId))
                const memberTeamsResponse = await doGet({ endpoint: getMemberTeams })
                setUserTeams(memberTeamsResponse?.data ?? [])
            }

            setMemberId(memberResponse?.data?.id)

            const getQualsEndpoint = generateEndpoint(apiPathGetMemberQuals(memberResponse?.data?.id))
            const qualsResponse = await doGet({ endpoint: getQualsEndpoint })

            setQualList(qualsResponse?.data ?? [])



        } catch (error) {
            console.error(error)
        }


    }

    const onCancel = async () => {
        await loadData()
        if (isEditingSelf) {
            doNavigate(dashboardRoute)
        } else {
            doNavigate(membersRoute)
        }

    }

    const cacheData = () => {

        let dob = userDateOfBirth ? dayjs(userDateOfBirth).toISOString() : undefined

        dispatchEditMembersCache({
            userEmail,
            userFirstName,
            userLastName,
            userPreferredName,
            userAddress,
            userContactNumber,
            userGender,
            userDateOfBirth: dob,
            userEmergencyContactName,
            userEmergencyContactNumber,
            userRole,
            userAccessNeeds,
            userIndigenous,
            userAdditionalDetails,
            userAccessNeedsPrivate,
            userJoined,
            userImageFormData,
            userImageId,
        })
    }

    useEffect(() => {
        if (currentClubId && memberUuid) {
            loadData()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberUuid, currentClubId])

    useEffect(() => {
        if (!memberUuid) {
            doNavigate(dashboardRoute)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberUuid])
    

    const handleArchive = async () => {
      const endpoint = generateEndpoint(apiPathArchiveClubMember(clubMemberId));
      try {
        setIsArchiveLoading(true);

        doDelete({ endpoint: endpoint });
        
        setLocalClubMemberStatus(statusInactive)

        setClubMemberStatus(statusInactive)

        onSubmit();

        setIsArchiveLoading(false);

        setArchiveDialogOpen(false);

      } catch (error) {
        console.error(error);
        setIsArchiveLoading(false);
      }
    };


    return <BasePageComponent
        backRoute={membersRoute}
        pageTitle={isEditingSelf ? "Edit Profile" : "Members"}
        inlineContent={canEdit && <SaveCancelStack saveAction={handleSaveChanges} cancelAction={onCancel} />}
        snackbarLoading={snackbarLoading}
        clearEditCache={false}
    >

        <Dialog open={dialogOpen}>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContentText paddingX={"24px"}>
                If you delete this qualification, it cannot be undone and must be re-added manually!
            </DialogContentText>
            <DialogActions>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
                <Button onClick={deleteQual}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
        <CardContainer padding="20px">

            <Stack direction="column" spacing={5}>
                <Typography fontSize={"24px"} lineHeight={"29.26px"} color={'#020205'}>
                    {userFullName} ({userEmail})
                </Typography>

                <Stack direction="row" spacing={rowSpacing}>
                    <Box width={fieldWidth} >
                        <PhotoBox
                            readOnly={!canEdit}
                            minioId={userImageId}
                            setMinioId={setUserImageId}
                            setFormData={setUserImageFormData}
                        />
                    </Box>

                    <Stack direction={"column"}>
                        <Box height="40%">
                            <Stack direction="column" spacing={2}>
                                <FieldLabel label={"Joined Club"} />
                                <Typography>
                                    {userJoined}
                                </Typography>
                            </Stack>

                        </Box>

                        <DropdownField readOnly={isEditingSelf || !isAdmin} label={"Membership Type"} value={userRole} setValue={setUserRole} options={memberTypeDropdownOptions} />
                    </Stack>
                    <Stack direction={"column"}>
                        <Box height="40%">
                            <Stack direction="column" spacing={2}>
                                <FieldLabel label={"Status"} />
                                <Stack direction={"row"} spacing={2}>
                                    <StatusCircleComponent status={localClubMemberStatus}/>
                                    <Typography>
                                        {localClubMemberStatus === statusActive ? "Active" : "Inactive"}
                                        <IconButton
                                        disableRipple
                                        disableFocusRipple
                                        sx={{ width: "10px", height: "10px", ml: "10px" }}
                                        onClick={onClickMenu}
                                        >
                                            <ArrowDropDownIcon fontSize="10px" style={{color: "#979797"}}/>
                                        </IconButton>
                                        <ArchiveMemberDialog 
                                        archiveDialogOpen={archiveDialogOpen} 
                                        setArchiveDialogOpen={setArchiveDialogOpen} 
                                        handleArchive={handleArchive} 
                                        isArchiveLoading={isArchiveLoading}/>
                                        <RadioStatusMenu  
                                        anchorEl={anchorEl} 
                                        open={isMenuOpened} 
                                        onClick={onClickMenu} 
                                        onClose={onCloseMenu} 
                                        setLocalClubMemberStatus={setLocalClubMemberStatus} 
                                        localClubMemberStatus={localClubMemberStatus}/>
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>
                </Stack>

                <Grid container columns={2} rowSpacing={rowSpacing}>
                    <Grid item xs={2}>
                        <FormField label={"Email Address"} readOnly={!canEdit} value={userEmail} setValue={setUserEmail} />
                    </Grid>
                    <Grid item xs={2}>
                        <FieldLabel label={"Personal Information:"} color={blueButtonColor} />
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <FormField label="First Name" readOnly={!canEdit} value={userFirstName} setValue={setUserFirstName} />
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <FormField label="Last Name" readOnly={!canEdit} value={userLastName} setValue={setUserLastName} />
                    </Grid>
                    <Grid item xs={2}>
                        <FormField label="Preferred Name" readOnly={!canEdit} value={userPreferredName} setValue={setUserPreferredName} />
                    </Grid>
                    <Grid item xs={2}>
                        <GenderField value={userGender} readOnly={!canEdit} setValue={setUserGender} />
                    </Grid>
                    <Grid item xs={2}>
                        <DatePickerField maxDate={dayjs()} label="DOB" readOnly={!canEdit} value={userDateOfBirth} setValue={setUserDateOfBirth} />
                    </Grid>
                    <Grid item xs={2}>
                        <IndigenousField readOnly={!canEdit} value={userIndigenous} setValue={setUserIndigenous} />
                    </Grid>
                    <Grid item xs={2}>
                        <FieldLabel label={"Contact Information:"} color={blueButtonColor} />

                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <FormField label={"Contact Number"} readOnly={!canEdit} value={userContactNumber} setValue={setUserContactNumber} />
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <GoogleMapsField label="Address" readOnly={!canEdit} value={userAddress} setValue={setUserAddress} />
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <FormField label={"Emergency Contact"} readOnly={!canEdit} value={userEmergencyContactName} setValue={setUserEmergencyContactName} />
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <FormField label={"Emergency Contact Number"} readOnly={!canEdit} value={userEmergencyContactNumber} setValue={setUserEmergencyContactNumber} />
                    </Grid>
                    {accessNeedsVisible && <Grid item xs={2}>
                        <FieldLabel label={"Access Needs:"} color={blueButtonColor} />
                    </Grid>}
                    {accessNeedsVisible && <Grid item xs={2}>
                        <AccessNeedsField readOnly={!canEdit} value={userAccessNeeds} setValue={setUserAccessNeeds} />
                    </Grid>}
                    {accessNeedsVisible && <Grid item xs={2}>
                        <MultilineField
                            readOnly={!canEdit}
                            label="Additional Details"
                            width="60ch"
                            value={userAdditionalDetails}
                            setValue={setUserAdditionalDetails}
                        />
                    </Grid>}

                    {isEditingSelf && <Grid item xs={2}>
                        <BooleanField
                            value={userAccessNeedsPrivate}
                            setValue={setUserAccessNeedsPrivate}
                            width={"60ch"}
                            trueLabel="No"
                            falseLabel="Yes"
                            flipOptions
                            label={"Would you like other club members to know about your access needs?"}
                        />
                    </Grid>}
                </Grid>
            </Stack>

            <FormTableComponent
                title={"Teams"}
                searchItems={mappedTeams}
                items={userTeams ?? []}
                readOnly
                itemEquality={(searchItem, rowItem) => equals(searchItem?.value)(rowItem?.teamId)}
                columns={[
                    { title: "Team Name", getValue: row => row?.teamName },
                    { title: "Description", getValue: row => getTeamById(row?.teamId)?.details?.description },
                    { title: "Position", getValue: row => row?.position, canEdit: true },
                ]}
            />

            <FormTableComponent
                title={"Qualifications"}
                inlineTitleContent={
                    <Typography
                        color={blueButtonColor}
                        sx={{ textDecoration: "underline", cursor: "pointer" }}

                        onClick={() => {
                            cacheData()
                            doNavigate(qualRoute, {target: memberUuid, qual_member_id: memberId})
                        }}>
                        Add new qualifications
                    </Typography>
                }
                searchItems={[]}
                items={mappedQuals}
                readOnly
                allowDeleteOnReadOnly
                onRemove={(item) => {
                    setDeletingQual(item?.id)
                    setDialogOpen(true)
                }}
                columns={[
                    {
                        title: "Qualification Type",
                        getValue: row => row?.type
                    },
                    {
                        title: "Proof of Certification",
                        getValue: row => row?.uploadId,
                        type: columnTypeDocument
                    },
                    {
                        title: "Expiry Date",
                        getValue: row => row?.expiry,
                        type: columnTypeExpiry
                    },
                ]}
            />

        </CardContainer>

    </BasePageComponent>

}

export const ArchiveMemberDialog = ({
    archiveDialogOpen,
    setArchiveDialogOpen,
    handleArchive,
    isArchiveLoading,
  }) => {
    return (
      <BaseDialog
        open={archiveDialogOpen}
        onClose={() => setArchiveDialogOpen(false)}
        title={"Are you sure you want to make this member Inactive?"}
        titleProps={{ textAlign: "center", fontSize: "24px" }}
        content={
          <Stack direction={"column"} alignContent={"center"} gap={"10px"}>
            <Typography
              color={"#3C3352"}
              letterSpacing={"2px"}
              fontSize={"14px"}
              fontWeight={"600"}
              textAlign={"center"}
            >
              This will remove their login privileges and access to the system
            </Typography>
          </Stack>
        }
        actionProps={{ sx: { justifyContent: "center", marginBottom: "20px" } }}
        paperProps={{
          sx: { border: "1px solid rgba(50, 62, 89, 1)", paddingX: "30px" },
        }}
        actions={
          <Stack direction={"row"} gap={"25px"}>
            <ButtonComponent
              title={"Confirm"}
              icon={<ThumbUp fontSize="small" />}
              background={greenButtonColor}
              onClick={handleArchive}
              disabled={isArchiveLoading}
            />
            <ButtonComponent
              disabled={isArchiveLoading}
              title={"Cancel"}
              icon={<CloseIcon fontSize="small" />}
              background={redButtonColor}
              onClick={() => setArchiveDialogOpen(false)}
            />
          </Stack>
        }
      />
    );
  };

export const RadioStatusMenu = ({
  anchorEl,
  open,
  onClick,
  onClose,
  setLocalClubMemberStatus,
  localClubMemberStatus,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClick}
      onClick={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          gap: "5px",
          overflow: "visible",
          width: 150,
          filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.32))",
          mt: 1.5,
          borderRadius: "4px",
          marginLeft: "-50px",
          outline: "1px solid #1890FF",
        },
      }}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        sx={{ height: "35px", ":hover": { background: "transparent" } }}
        disableRipple
        disableTouchRipple
        disableFocusRipple
        onClick={() => setLocalClubMemberStatus(statusActive)}
      >
        <Stack
          direction={"row"}
          gap={"10px"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {localClubMemberStatus === statusActive ? (
            <RadioButtonCheckedIcon style={{ color: "#1890FF" }} />
          ) : (
            <RadioButtonUncheckedIcon
              style={{ color: "rgba(0, 0, 0, 0.54)" }}
            />
          )}
          <Typography
            color={statusColors[statusActive]}
            textAlign={"center"}
            fontWeight={"500"}
            fontSize={"14px"}
          >
            Active
          </Typography>
        </Stack>
      </MenuItem>

      <MenuItem
        sx={{ height: "35px", ":hover": { background: "transparent" } }}
        disableRipple
        disableTouchRipple
        disableFocusRipple
        onClick={() => setLocalClubMemberStatus(statusInactive)}
      >
        <Stack
          direction={"row"}
          gap={"10px"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {localClubMemberStatus === statusInactive ? (
            <RadioButtonCheckedIcon style={{ color: "#1890FF" }}/>
          ) : (
            <RadioButtonUncheckedIcon
              style={{ color: "rgba(0, 0, 0, 0.54)" }}
            />
          )}
          <Typography
            color={statusColors[statusInactive]}
            textAlign={"center"}
            fontWeight={"500"}
            fontSize={"14px"}
          >
            Inactive
          </Typography>
        </Stack>
      </MenuItem>
    </Menu>
  );
};



export default EditMemberPage
