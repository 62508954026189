import {
  Box,
  ButtonBase,
  Checkbox,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import FieldLabel from "../fields/field-label";
import { equals, find, identity, length, reject } from "ramda";
import { Add, Close } from "@mui/icons-material";
import profilePic from "../../assets/default-profile-2.png";
import SearchAutocompleteField from "../fields/search-autocomplete-field";
import {
  blueButtonColor,
  greenButtonColor,
  redButtonColor,
  statusText
} from "../../styles/style-constants";
import { Fragment, useState } from "react";
import {
  columnTypeBoolean,
  columnTypeDate,
  columnTypeDocument,
  columnTypeDropdown,
  columnTypeDuration,
  columnTypeExpiry,
  columnTypeFloat,
  columnTypeStatus,
  columnTypeString,
  columnTypeAssign
} from "../table-component";
import FormField from "../fields/form-field";
import StatusCircleComponent from "../status-circle-component";
import FloatFormField from "../fields/float-form-field";
import DatePickerField from "../fields/date-picker-field";
import DocumentField from "../fields/document-field";
import {
  isNotNullOrUndefinedOrEmpty
} from "../../utils/helper-functions/is-null-or-undefined-or-empty";
import dayjs from "dayjs";
import DownloadButton from "../download-button";
import { statusColors } from "../../styles/style-constants";
import DoneIcon from "@mui/icons-material/Done";
import MenuDropDown from "../menu-dropdown-component";
import RecommendIcon from "@mui/icons-material/Recommend";
import HelpIcon from "@mui/icons-material/Help";
import CancelIcon from "@mui/icons-material/Cancel";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const GridCell = ({
  column,
  item,
  onUpdate,
  disabled,
  readOnly,
  columnOpts = {},
}) => {
  const colReadOnlyOverride = column?.readOnlyOverride ?? false;
  const colCanEdit = column?.canEdit ?? false;
  const itemDisabled = item?.disabled ?? false;
  const colNotDisabled = !disabled && !readOnly && colCanEdit;

  // im not very good at destructuring right this second so I will just get this working
  const showStatusText = columnOpts?.showStatusText ?? false;

  const canEdit = colReadOnlyOverride || colNotDisabled;
  // This should probably be abstracted into two different types of columnTypes as it is quite messsy.
  // I can clean this up in the future if time persisits
  if (column?.type === columnTypeStatus) {
    const status = column?.getValue && column.getValue(item)?.status;
    const columnValue = column?.getValue && column.getValue(item);
    // default: true
    const allowDecline = columnOpts.allowDecline === undefined || columnOpts.allowDecline;

    // This will be improved w/ better abstraction once I get the chance

    const activityRowEdit = status === "ACCEPTED" || status === "ASSIGNED";

    const memberRowEdit =
      status === "CONFIRMED" ||
      status === "UNCONFIRMED" ||
      status === "DECLINED";

    const activityStatusOverride = columnValue?.canEdit && activityRowEdit;
    const currentUserRow =
      columnValue?.rowUuid === column.getValue(item)?.currentUser;
    const showActivitesActions =
      !columnValue?.canEdit && activityRowEdit && currentUserRow;
    const showMemberActions =
      !columnValue?.canEdit && memberRowEdit && currentUserRow;
    const memberStatusOverride =
      columnValue?.canEdit && memberRowEdit;

    const mappedStatus = statusText[status] ?? status

    return showStatusText ? (
      <Stack direction="row" spacing={1} width={"100%"} alignItems={"center"}>
        <StatusCircleComponent status={status} />
        <Typography
          paddingTop={"5px"}
          fontWeight={500}
          color={statusColors[status]}
          fontSize={12}
        >
          {mappedStatus}
        </Typography>
        {activityStatusOverride && (
          <MenuDropDown
            activeIcon={<DoneIcon sx={{ color: "#1890FF", mb: "3.5px" }} />}
            items={[
              {
                icon: (
                  <RecommendIcon fontSize="large" sx={{ color: "#15AC1D" }} />
                ),
                title: "CONFIRMED",
                theme: "#15AC1D",
                isActive: status === "ACCEPTED",
                onClick: () => { onUpdate(item, column?.setPath, "ACCEPTED") },
              },
              {
                icon: <HelpIcon fontSize="large" sx={{ color: "#FFAE35" }} />,
                title: "UNCONFIRMED",
                theme: "#FFAE35",
                isActive: status === "ASSIGNED",
                onClick: () => { onUpdate(item, column?.setPath, "ASSIGNED") },
              },
              {
                icon: <CancelIcon fontSize="large" sx={{ color: "#F41616" }} />,
                title: "UNASSIGNED",
                theme: "#F41616",
                onClick: () => { 
                  onUpdate(item, column?.setPath, "VACANT")
                },
              },
            ]}
          />
        )}
        {showActivitesActions && (
          <MenuDropDown
            activeIcon={<DoneIcon sx={{ color: "#1890FF", mb: "3.5px" }} />}
            decoration={columnValue?.decoration}
            items={[
              {
                icon: (
                  <RecommendIcon fontSize="large" sx={{ color: "#15AC1D" }} />
                ),
                title: "CONFIRMED",
                theme: "#15AC1D",
                isActive: status === "ACCEPTED",
                onClick: () => columnValue?.onMemberAccept(item, column?.setPath),
              },
              {
                icon: <HelpIcon fontSize="large" sx={{ color: "#FFAE35" }} />,
                title: "UNCONFIRMED",
                theme: "#FFAE35",
                isActive: status === "ASSIGNED",
                onClick: () => columnValue?.onMemberUnconfirm(item, column?.setPath),
                disabled: !allowDecline
              },
              {
                icon: <CancelIcon fontSize="large" sx={{ color: "#F41616" }} />,
                title: "UNASSIGNED",
                theme: "#F41616",
                onClick: () => columnValue?.onMemberDecline(item, column?.setPath),
                disabled: !allowDecline
              },
              {
                icon: (
                  <AccountCircleIcon
                    fontSize="large"
                    sx={{ color: "#1890FF" }}
                  />
                ),
                title: "Delegate",
                theme: "#1890FF",
                onClick: columnValue?.onMemberDelegate,
                decoration: columnValue?.decoration,
              },
            ]}
          />
        )}
        {(showMemberActions || memberStatusOverride) && (
          <MenuDropDown
            activeIcon={<DoneIcon sx={{ color: "#1890FF", mb: "3.5px" }} />}
            items={[
              {
                icon: (
                  <RecommendIcon fontSize="large" sx={{ color: "#15AC1D" }} />
                ),
                title: "CONFIRMED",
                theme: "#15AC1D",
                isActive: status === "CONFIRMED",
                onClick: columnValue?.onMemberConfirm,
              },
              {
                icon: <HelpIcon fontSize="large" sx={{ color: "#FFAE35" }} />,
                title: "UNCONFIRMED",
                theme: "#FFAE35",
                isActive: status === "UNCONFIRMED",
                onClick: columnValue?.onMemberUnconfirm,
              },
              {
                icon: <CancelIcon fontSize="large" sx={{ color: "#F41616" }} />,
                title: "DECLINED",
                theme: "#F41616",
                isActive: status === "DECLINED",
                onClick: columnValue?.onMemberDecline,
              },
            ]}
          />
        )}
      </Stack>
    ) : (
      <StatusCircleComponent status={status} />
    );
  }

  if (!canEdit) {
    switch (column?.type ?? columnTypeString) {
      case columnTypeDocument:
        return (
          <DownloadButton
            disabled={!item?.needsCert}
            background={item?.expired ? redButtonColor : greenButtonColor}
            title="View"
            uuid={column?.getValue(item)}
          />
        );

      case columnTypeDate:
        return column?.getValue && column?.getValue(item).format("DD/MM/YYYY");

      case columnTypeExpiry:
        if (!item?.needsCert) {
          return <Typography color="#B1B1B1">Not Applicable</Typography>;
        } else {
          const value = dayjs(column?.getValue(item));

          return (
            <Typography
              color={item?.expired ? redButtonColor : greenButtonColor}
            >
              {value.format("DD/MM/YYYY")}
            </Typography>
          );
        }
      case columnTypeFloat:
        return (
          <FloatFormField
            width={"6ch"}
            placeholder={column?.title}
            label={undefined}
            value={column?.getValue(item)}
            readOnly={true}
            disabled={disabled}
            decoration={"X"}
            setValue={(value) => onUpdate(item, column?.setPath, value)}
          />
        );
      case columnTypeDropdown:
        return column?.getOptionLabel(column.getValue(item));
      case columnTypeDuration:
        return (
          <FloatFormField
            readOnly={true}
            width={"10ch"}
            decoration={"hr"}
            placeholder={column?.title}
            label={undefined}
            value={column?.getValue(item)}
            setValue={(value) => onUpdate(item, column?.setPath, value)}
          />
        );
      case columnTypeAssign:
        // TODO: Ensure this works as expected
        return column?.getValue && column?.getValue(item);
      case columnTypeString:
      default:
        return column?.getValue && column.getValue(item);
    }
  } else {
    switch (column?.type ?? columnTypeString) {
      case columnTypeDropdown:
        if (isNotNullOrUndefinedOrEmpty(column?.options)) {
          return (
            <SearchAutocompleteField
              width="20ch"
              value={column?.getValue(item)}
              options={column?.options ?? []}
              getOptionLabel={column?.getOptionLabel ?? identity}
              variant={"standard"}
              label=""
              setValue={(value) => onUpdate(item, column?.setPath, value)}
            />
          );
        }
      // eslint-disable-next-line no-fallthrough
      case columnTypeBoolean:
        if (itemDisabled) {
          return (
            <Tooltip title="You already have this qualification!">
              <Checkbox sx={{ opacity: 0.5 }} checked={true} />
            </Tooltip>
          );
        } else {
          return (
            <Checkbox
              checked={column?.getValue(item) ?? false}
              onChange={(_) =>
                onUpdate(item, column?.setPath, !column?.getValue(item))
              }
            />
          );
        }
      case columnTypeFloat:
        return (
          <FloatFormField
            width={"7ch"}
            placeholder={column?.title}
            label={undefined}
            value={column?.getValue(item)}
            readOnly={!canEdit}
            disabled={disabled}
            decoration={"X"}
            setValue={(value) => onUpdate(item, column?.setPath, value)}
          />
        );
      case columnTypeDate:
        return (
          <DatePickerField
            readOnly={!canEdit}
            value={column?.getValue(item)}
            setValue={(value) => onUpdate(item, column?.setPath, value)}
            label={undefined}
            width="15ch"
            spacing={0}
          />
        );
      case columnTypeDocument:
        return (
          <DocumentField
            readOnly={!canEdit}
            setValue={(value) => onUpdate(item, column?.setPath, value)}
            value={column?.getValue(item)}
          />
        );
      case columnTypeDuration:
        return (
          <FloatFormField
            readOnly={!canEdit}
            width={"8ch"}
            decoration={"hr"}
            placeholder={column?.title}
            label={undefined}
            value={column?.getValue(item)}
            setValue={(value) => onUpdate(item, column?.setPath, value)}
          />
        );
      case columnTypeString:
      default:
        return (
          <FormField
            width="20ch"
            placeholder={column?.title}
            readOnly={!canEdit}
            label={undefined}
            value={column?.getValue(item)}
            setValue={(value) => onUpdate(item, column?.setPath, value)}
          />
        );
    }
  }
};

const FormTableComponent = ({
  title,
  inlineTitleContent,
  marginTop = 5,
  onUpdate = identity,
  columns = [],
  searchItems = [],
  columnOpts = {},
  items = [],
  onAdd = (item) => {},
  onRemove = (item) => {},
  itemEquality = (searchItem, rowItem) =>
    equals(searchItem?.id ?? searchItem?.value, rowItem?.id ?? rowItem?.value),
  disabled = false,
  readOnly = false,
  multiAdd = false,
  allowDeleteOnReadOnly = false,
}) => {
  const [searchValue, setSearchValue] = useState();

  const columnXs = 4;

  const columnCount = length(columns) * columnXs + 1;

  const onClick = () => {
    onAdd(searchValue);
    setSearchValue(null);
  };

  const findItem = (a) => find((b) => itemEquality(a, b));
  const hasItem = (a) => (list) =>
    isNotNullOrUndefinedOrEmpty(findItem(a)(list));

  const mappedSearchItems = reject((item) => hasItem(item)(items))(searchItems);

  const columnHeaders = columns.map((column, index) => (
    <Grid
      item
      key={index}
      xs={column?.xs ?? columnXs}
      sx={{ width: "100%", marginY: "auto" }}
    >
      <FieldLabel label={column?.title} width={"100%"} disabled={disabled} />
    </Grid>
  ));

  const mapRow = (item, rowIndex) => {
    let out = columns.map((column, index) => (
      <Grid
        item
        xs={column?.xs ?? columnXs}
        key={`${rowIndex}-${index}`}
        textOverflow={"ellipsis"}
        overflow={"clip"}
        marginY={"auto"}
        width="min-content"
      >
        <GridCell
          columnOpts={columnOpts}
          readOnly={readOnly}
          disabled={disabled}
          item={item}
          column={column}
          onUpdate={onUpdate}
        />
      </Grid>
    ));
    out = [
      ...out,
      <Grid key={`${rowIndex}-${columnCount}`} item xs={1} paddingY="1rem">
        <ButtonBase
          disabled={(disabled || readOnly) && !allowDeleteOnReadOnly}
          onClick={() => onRemove(item)}
        >
          <Close
            style={{
              color:
                (disabled || readOnly) && !allowDeleteOnReadOnly
                  ? "transparent"
                  : "#595959",
            }}
          />
        </ButtonBase>
      </Grid>,
    ];

    return out;
  };

  return (
    <Box marginTop={marginTop}>
      <Stack direction="row" justifyContent={"space-between"}>
        <FieldLabel label={title} disabled={disabled} />
        {inlineTitleContent}
      </Stack>
 
      <Grid container columns={columnCount} width="100%" marginTop={2}>
        {columnHeaders.map((columnItem) => columnItem)}

        <Grid item xs={columnCount} width="100%" marginY={1}>
          <Divider />
        </Grid>
      </Grid>
      {!readOnly && (
        <Stack direction="row" justifyContent={"space-between"} marginRight={2}>
          <Stack direction="row" gap="30px">
            <Box
              component="img"
              src={profilePic}
              alt="Club Profile"
              sx={{
                padding: "10px",
                borderRadius: "100%",
                objectFit: "contain",
                height: "60px",
              }}
            />

            <SearchAutocompleteField
              width={"300px"}
              isMulti={multiAdd}
              disabled={disabled}
              options={mappedSearchItems}
              value={searchValue}
              setValue={setSearchValue}
            />
          </Stack>
          <ButtonBase
            disabled={!searchValue || disabled}
            onClick={onClick}
            sx={{
              height: "40px",
              width: "40px",
              marginY: "auto",
              borderRadius: "100%",
              background: blueButtonColor,
              color: "white",
              opacity: !searchValue || disabled ? 0.5 : 1.0,
            }}
          >
            <Add />
          </ButtonBase>
        </Stack>
      )}

      <Grid container columns={columnCount} width="100%">
        <Grid item xs={columnCount} width="100%">
          <Divider />
        </Grid>

        {items.map((item, index) => (
          <Fragment key={index}>
            {mapRow(item, index)}
            <Grid item xs={columnCount + 1}>
              <Divider />
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </Box>
  );
};

export default FormTableComponent;
