import { Stack } from "@mui/material";
import GooglePlayImage from "../../../assets/GooglePlayImage.png";

const MobileAppStack = () => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"center"}
      gap={"35px"}
      mt={"5px"}
    >
      <a
        href="https://apps.apple.com/au/app/memberclubteams/id6478431441?itsct=apps_box_link&itscg=30200"
        style={{
          display: "inline-block",
          overflow: "hidden",
          borderRadius: "13px",
          width: "145px",
          height: "45px",
        }}
      >
        <img
          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1712188800"
          alt="Download on the App Store"
          style={{
            borderRadius: "13px",
            width: "145px",
            height: "45px",
          }}
        />
      </a>
      <a
        style={{
          width: "145px",
          height: "45px",
          display: "inline-block",
          overflow: "hidden",
          marginBottom: "0.5px"

        }}
        href="https://play.google.com/store/apps/details?id=com.memberclubteams.frontend&pcampaignid=web_share"
      >
        <img alt={"Google Play Download"} src={GooglePlayImage} style={{ width: "140px", height: "45px" }} />
      </a>
    </Stack>
  );
};

export default MobileAppStack;
