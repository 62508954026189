import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Stack, Typography } from "@mui/material"
import { equals, head, reject, update } from "ramda"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import BasePageComponent from "../../components/base-page-component"
import ButtonComponent from "../../components/button-component"
import SaveCancelAddStack from "../../components/buttons/save-cancel-add-stack"
import CardContainer from "../../components/card-container"
import BaseDialog from "../../components/dialog-component"
import BooleanField from "../../components/fields/boolean-field"
import FormHeader from "../../components/form-header-component"
import ObjectListComponent from "../../components/object-list-component"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { selectCurrentClubId } from "../../redux/selectors"
import { darkBlueButtonColor, redButtonColor } from "../../styles/style-constants"
import { newFormEntityId } from "../../utils/constants"
import { apiPathCreateQualificationType, apiPathDeleteQualificationType, apiPathGetQualificationTypeList, apiPathPutQualificationType } from "../../utils/endpoint-paths"
import { isNullOrUndefinedOrEmpty } from "../../utils/helper-functions/is-null-or-undefined-or-empty"
import useDebounce from "../../utils/use-debounce"

const QualificationsPage = () => {

    const { generateEndpoint, doGet, doPut, doPost, uploadPfp, doDelete } = useApiUtilsContext()


    const [searchParams, setSearchParams] = useSearchParams()
    const currentQualId = searchParams.get("target") ?? undefined
    const setCurrentQualId = (id) => setSearchParams({ target: id })

    const [qualInput, setQualInput] = useState("")
    const [qualSearch, setQualSearch] = useState()

    const currentClubId = useSelector(selectCurrentClubId);

    const getQualsListEndpoint = generateEndpoint(apiPathGetQualificationTypeList(currentClubId))
    const updateQualEndpoint = generateEndpoint(apiPathPutQualificationType(currentClubId, currentQualId))
    const createQualEndpoint = generateEndpoint(apiPathCreateQualificationType(currentClubId))

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [imageId, setImageId] = useState();
    const [imageFormData, setImageFormData] = useState()
    const [cert, setCert] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const isCreatingNew = currentQualId === newFormEntityId


    const [quals, setQuals] = useState([]);

    const [loading, setLoading] = useState(false)

    const currentQual = quals.find((qual) => `${qual.id}` === `${currentQualId}`);

    const currentQualIndex = quals.findIndex((qual) => `${qual.id}` === `${currentQualId}`);


    const disabled = currentQualId === undefined


    const onSubmit = async () => {
        setLoading(true)
        try {

            let pictureId = imageId
            if (imageFormData) {
                pictureId = await uploadPfp(imageFormData)
            }

            const body = {
                details: {
                    name: title,
                    description: description,
                    pictureId
                },
                needsCertification: cert,
            };
            if (isCreatingNew) {


                const response = await doPost({
                    endpoint: createQualEndpoint,
                    body: body
                });

                const newQualResponse = await doGet({
                    endpoint: response?.headers?.location
                });

                //Set new stuff
                updateCurrentQual(newQualResponse.data);
            } else {


                await doPut({
                    endpoint: updateQualEndpoint,
                    body: body
                });

                //Set new stuff
                updateCurrentQual({ id: currentQualId, ...body });
            }
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    };

    useDebounce(() => {
        setQualSearch(qualInput)
    }, 500, [qualInput])

    useEffect(() => {
        const getQualsList = async () => {
            const queryParams = {
                name: qualSearch?.length > 0 ? qualSearch : undefined
            }
            try {
                const response = await doGet({ endpoint: getQualsListEndpoint, queryParams })
                setQuals(response?.data)
            } catch (error) {
                console.error(error)
            }
        }

        if (currentClubId) {
            getQualsList()
        }
    }, [currentClubId, doGet, getQualsListEndpoint, qualSearch]);

    useEffect(() => {
        if (currentQualId) {

            setTitle(currentQual?.details?.name ?? "");
            setDescription(currentQual?.details?.description ?? "");
            setCert(currentQual?.needsCertification ?? "");
            setImageId(currentQual?.details?.pictureId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentClubId, currentQualId, quals]);

    const addNewQual = () => {
        setCurrentQualId(newFormEntityId);
        setQuals((prev) => [
            { id: newFormEntityId },
            ...prev
        ])
    };

    const onCancel = () => {
        if (isCreatingNew) {
            const newQuals = reject((qual) => equals(qual?.id)(newFormEntityId))(quals)
            setQuals(newQuals)
            setCurrentQualId(head(newQuals)?.id)
        } else {
            setTitle(currentQual?.details?.name ?? "");
            setDescription(currentQual?.details?.description ?? "");
            setCert(currentQual?.needsCertification ?? "");
            setImageId(currentQual?.details?.pictureId ?? null)
        }
    };

    const updateCurrentQual = (newQual) => {
        //update the current details to new details
        setQuals((prev) => update(currentQualIndex, newQual, prev));
        setCurrentQualId(newQual?.id);
    };

    const onDelete = async () => {
        const deleteEndpoint = generateEndpoint(apiPathDeleteQualificationType(currentQualId))
        try {
            setIsDeleteLoading(true);
            await doDelete({ endpoint: deleteEndpoint });
            setIsDeleteLoading(false);
            setOpenDeleteDialog(false);
            setQuals((prev) => {
                const newQualList = reject((qual) => {
                    return equals(qual?.id)(parseInt(currentQualId))
                }, prev);
                return newQualList;
            });
            setCurrentQualId(undefined);
            setSearchParams({});
        } catch (error) {
            setIsDeleteLoading(false)
            console.error(error)
        }

    }

    return <BasePageComponent
        pageTitle={"Qualifications"}
        marginRight="60px"
        snackbarLoading={loading}
        inlineContent={
            <SaveCancelAddStack
                addLabel="Add New Qualification"
                addAction={addNewQual}
                saveAction={onSubmit}
                cancelAction={onCancel}
                deleteComponent={
                    <ButtonComponent
                        title={"Delete Qualification Type"}
                        icon={<DeleteOutlinedIcon />}
                        background={darkBlueButtonColor}
                        onClick={() => setOpenDeleteDialog(true)}
                        disabled={
                            disabled
                        }
                    />
                }
                disableSave={
                    disabled ||
                    isNullOrUndefinedOrEmpty(title) ||
                    isNullOrUndefinedOrEmpty(description) ||
                    isNullOrUndefinedOrEmpty(cert)
                }
                disableCancel={disabled}
                disableAdd={currentQualId === newFormEntityId}
            />

        }
    ><DeleteEventTypeDialog
            openDeleteDialog={openDeleteDialog}
            setOpenDeleteDialog={setOpenDeleteDialog}
            handleDelete={onDelete}
            isDeleteLoading={isDeleteLoading}
        />
        <Stack direction="row" spacing={5} padding={5}>
            <ObjectListComponent
                title={"Qualifications"}
                searchPlaceholder={"Search for Qualification Type..."}
                items={quals}
                getItemName={(qual) => qual?.details?.name ?? "New Qualification"}
                onSelectItem={(qual) => setCurrentQualId(qual?.id)}
                selectedKey={currentQualId}
                searchValue={qualInput}
                setSearchValue={setQualInput}
            />

            <CardContainer padding={"20px 30px 50px 30px"}>
                <Stack direction="column" spacing={6}>
                    <FormHeader
                        label={"Qualification"}
                        title={title}
                        setTitle={setTitle}
                        description={description}
                        setDescription={setDescription}
                        minioId={imageId}
                        setMinioId={setImageId}
                        setFormData={setImageFormData}
                        disabled={disabled}
                        mandatory
                    />
                    <BooleanField
                        mandatory
                        label="Certification Required?"
                        value={cert}
                        setValue={setCert}
                        disabled={disabled}
                    />
                </Stack>
            </CardContainer>

        </Stack>


    </BasePageComponent>


}

export const DeleteEventTypeDialog = ({
    openDeleteDialog,
    setOpenDeleteDialog,
    handleDelete,
    isDeleteLoading,
}) => {
    return (
        <BaseDialog
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
            title={"Delete Qualification Type?"}
            titleProps={{ textAlign: "center", fontSize: "24px" }}
            content={
                <Stack direction={"column"} alignContent={"center"} gap={"10px"}>
                    <Typography
                        color={"#3C3352"}
                        letterSpacing={"2px"}
                        fontSize={"14px"}
                        fontWeight={"600"}
                        textAlign={"center"}
                    >
                        Are you sure you want to delete?
                    </Typography>
                    <Typography
                        color={"#3C3352"}
                        letterSpacing={"2px"}
                        fontSize={"14px"}
                        fontWeight={"600"}
                        textAlign={"center"}
                    >
                        (Please note, this action cannot be reversed)
                    </Typography>
                </Stack>
            }
            actionProps={{ sx: { justifyContent: "center", marginBottom: "20px" } }}
            paperProps={{
                sx: { border: "1px solid rgba(50, 62, 89, 1)", paddingX: "30px" },
            }}
            actions={
                <Stack direction={"row"} gap={"25px"}>
                    <ButtonComponent
                        title={"Delete"}
                        icon={<DeleteOutlinedIcon fontSize="small" />}
                        background={darkBlueButtonColor}
                        onClick={handleDelete}
                        disabled={isDeleteLoading}
                    />
                    <ButtonComponent
                        disabled={isDeleteLoading}
                        title={"Cancel"}
                        icon={<CloseIcon fontSize="small" />}
                        background={redButtonColor}
                        onClick={() => setOpenDeleteDialog(false)}
                    />
                </Stack>
            }
        />
    );
};

export default QualificationsPage