import CardContainer from "../../components/card-container";
import { Stack, Box, Typography, Divider } from "@mui/material";
import PublishSharpIcon from "@mui/icons-material/PublishSharp";
import ButtonComponent from "../../components/button-component";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useRef, useState } from "react";
import Papa from "papaparse";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { redButtonColor } from "../../styles/style-constants";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const InitalUploadPage = ({
  handleDrop,
  handleFileChange,
  fileContent,
  handleSelectFile,
  removeFileHandler,
  fileInputRef,
  fileName,
  handleUploadToFinalise,
  handleEventsDownloadTemplate,
  isFileValid,
  handleSkipUpload
}) => {
  
    return (
    <CardContainer padding="20px" marginTop="15px">
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Stack
          width={"80%"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"280px"}
          borderRadius={"5px"}
          border={"2px dashed #C9CBD3"}
          mt={"30px"}
          mb={"40px"}
          direction={"column"}
          gap={"2px"}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          {!fileContent ? (
            <>
              <PublishSharpIcon sx={{ fontSize: "80px", color: "#323E59" }} />
              <Typography
                color={"rgba(119, 126, 144, 0.9)"}
                fontSize={"13px"}
                textAlign={"center"}
                lineHeight={"22px"}
              >
                Drag & Drop File Here <br /> Or
              </Typography>
              <Divider
                sx={{ width: "22.2%", marginTop: "4px", marginBottom: "12px" }}
              />
              <ButtonComponent
                title={
                  <Typography px={"10px"} fontFamily={"mulish"} fontSize={12}>
                    Select File
                  </Typography>
                }
                background="rgba(119, 126, 144, 0.4)"
                fontSize={"12px"}
                onClick={handleSelectFile}
              />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                accept=".csv"
              />
            </>
          ) : (
            <>
              <UploadFileIcon sx={{ fontSize: "70px", color: "#323E59" }} />
              <Typography
                color={"rgba(119, 126, 144, 0.9)"}
                fontSize={"13px"}
                textAlign={"center"}
                lineHeight={"22px"}
                mt={"5px"}
              >
                {fileName}
              </Typography>
              <Divider
                sx={{ width: "22.2%", marginY: "10px", marginBottom: "12px" }}
              />
              <ButtonComponent
                title={
                  <Typography px={"10px"} fontFamily={"mulish"} fontSize={12}>
                    Remove File
                  </Typography>
                }
                background="rgba(119, 126, 144, 0.4)"
                fontSize={"12px"}
                onClick={removeFileHandler}
              />
            </>
          )}
        </Stack>
      </Stack>
      <Stack ml={"9.5%"} direction={"row"} alignItems="center" mb={"35px"}>
        {!isFileValid && (
          <Typography
            fontSize={"12px"}
            color={redButtonColor}
            fontWeight={"500"}
            lineHeight={"1.5px"}
            display="flex"
            alignItems="center"
          >
            <ErrorOutlineIcon
              fontSize="small"
              sx={{ color: redButtonColor, marginRight: "8px" }}
            />
            The uploaded file does not match the expected template.
          </Typography>
        )}
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"} mx={"50px"}>
        <Stack direction={"row"} gap={"10px"} mb={"20px"}>
          <ButtonComponent
            title={
              <Typography
                px={"25px"}
                fontFamily={"mulish"}
                fontWeight={"500"}
                fontSize={"12px"}
              >
                Skip
              </Typography>
            }
            background="rgba(0, 0, 0, 0.1)"
            fontSize={"12px"}
            color="black"
            onClick={handleSkipUpload}
          />
          <ButtonComponent
            title={
              <Typography
                px={"50px"}
                fontFamily={"mulish"}
                fontSize={12}
                fontWeight={"500"}
              >
                Next
              </Typography>
            }
            background="#1890FF"
            fontSize={"12px"}
            disabled={!fileContent || !isFileValid}
            onClick={handleUploadToFinalise}
          />
        </Stack>

        {/* <ButtonComponent
          title={
            <Box display="flex" height={"100%"} alignItems={"center"}>
              <Typography px={"15px"} fontFamily={"mulish"} fontSize={12}>
                Download Template
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  backgroundColor: "white",
                  mr: "9px",
                  color: "white",
                  height: "31px",
                  width: "1.5px",
                }}
              />
              <ArrowDropDownIcon fontSize="small" />
            </Box>
          }
          background="#1890FF"
          fontSize={"12px"}
        /> */}
        <ButtonComponent
          title={
            <Box display="flex" height={"100%"} alignItems={"center"}>
              <Typography px={"15px"} fontFamily={"mulish"} fontSize={12}>
                Download Template
              </Typography>
            </Box>
          }
          onClick={handleEventsDownloadTemplate}
          background="#1890FF"
          fontSize={"12px"}
        />
      </Stack>
    </CardContainer>
  );
};

export default InitalUploadPage;
