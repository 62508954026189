import { useSelector } from "react-redux"
import { NotificationEventDetailsList, NotificationListItemBase, NotificationPageBase } from "../notification-type-base"
import { selectActivityFromCache, selectEventFromCache, selectUserFirstName } from "../../../redux/selectors"
import { Stack } from "@mui/material"
import { greenButtonColor, redButtonColor } from "../../../styles/style-constants"
import { useEffect } from "react"
import ButtonComponent from "../../../components/button-component"
import { ThumbDown, ThumbUp } from "@mui/icons-material"
import { useApiUtilsContext } from "../../../providers/api-utils-provider"
import { apiPathAcceptDelegation, apiPathDeclineDelegation } from "../../../utils/endpoint-paths"
import { isNotUndefined } from "../../../utils/helper-functions/is-null-or-undefined-or-empty"

const getBodyOpener = (name) => `Hey ${name}, `
const getBodyText = (delegatorName, activityName) => `${delegatorName} has delegated the following volunteering activity to you: ${activityName}`

const ListItem = ({
    notification,
    markAsRead,
    markAsUnread,
    addEventRequest,
    addActivityRequest,
    setSelectedNotification,
    selected
}) => {

    const name = useSelector(selectUserFirstName)


    const delegatorName = notification?.metadata?.delegator_name

    const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))
    const activity = useSelector(selectActivityFromCache(notification?.metadata?.activity_id))

    const activityName = activity?.details?.name ?? "..."
    useEffect(() => {



        addEventRequest(notification?.metadata?.event_id)
        addActivityRequest(notification?.metadata?.activity_id)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return <NotificationListItemBase
        setSelectedNotification={setSelectedNotification}
        selected={selected}
        notification={notification}
        getBodyText={() => getBodyOpener(name) + getBodyText(delegatorName, activityName)}
        markAsRead={markAsRead}
        markAsUnread={markAsUnread}
        getBody={() => <NotificationEventDetailsList
            event={event}
        />}
    />

}



const Page = ({ notification, refreshNotifications }) => {

    const name = useSelector(selectUserFirstName)


    const {generateEndpoint, doPut} = useApiUtilsContext()

    const confirmed = notification?.metadata?.confirmed

    const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))
    const activity = useSelector(selectActivityFromCache(notification?.metadata?.activity_id))
    const delegatorName = notification?.metadata?.delegator_name
    const activityName = activity?.details?.name ?? "..."

    const acceptEndpoint = generateEndpoint(apiPathAcceptDelegation(activity?.id))
    const declineEndpoint = generateEndpoint(apiPathDeclineDelegation(activity?.id))

    const onClick = async (accept) => {
        let endpoint = declineEndpoint
        if (accept) {
            endpoint = acceptEndpoint
        }
        try {
            await doPut({ endpoint, queryParams: { notif_id: notification?.id } })
            refreshNotifications()
        } catch (error) {
            console.error(error)
        }
    }

    const onAccept = async () => {
        onClick(true)
    }

    const onDecline = async () => {
        onClick(false)
    }

    return <NotificationPageBase
        notification={notification}
        getBodyOpener={() => getBodyOpener(name)}
        getBodyText={() => getBodyText(delegatorName, activityName)}
        getBody={() => <NotificationEventDetailsList
            event={event}
            showLocation
            showTime
            activity={activity}
            showActivity
        />}

        getActions={() => <Stack direction={"row"} spacing={2}>
            <ButtonComponent 
                icon={<ThumbUp />} 
                title={"Confirm"} 
                background={greenButtonColor}
                onClick={onAccept}
                disabled={isNotUndefined(confirmed)}
                disabledOpacity={confirmed ? 1.0 : 0.5}
            />
            <ButtonComponent 
                icon={<ThumbDown />} 
                title={"Decline"} 
                background={redButtonColor}
                onClick={onDecline}
                disabled={isNotUndefined(confirmed)}
                disabledOpacity={!confirmed ? 1.0 : 0.5}
            />
        </Stack>}
    />

}


const NotificationDelegatedActivity = {
    ListItem,
    Page
}

export default NotificationDelegatedActivity