import CardContainer from "../../components/card-container";
import { Stack, Typography } from "@mui/material";
import ButtonComponent from "../../components/button-component";
import useDoNavigate from "../../utils/do-navigate";
import { eventsRoute, importRoute } from "../../utils/page-routes";

const CompletedUpload = ({ uploadedEventsCount, setPage, removeFileHandler  }) => {
  const text =
    uploadedEventsCount > 1
      ? `${uploadedEventsCount} events have been successfully imported.`
      : `${uploadedEventsCount} event have been successfully imported.`;

  const doNavigate = useDoNavigate();
  return (
    <CardContainer padding="45px" marginTop="15px">
      <Stack direction={"column"} width={"100%"} spacing={4} mx={"15px"}>
        <Typography color={"#323E59"} fontWeight={"500"} fontSize={"24px"}>
          Successfully Uploaded!
        </Typography>

        <Typography color={"#323E59"} fontWeight={"500"} fontSize={"14px"}>
          {text}
        </Typography>

        <Typography color={"#1890FF"} fontWeight={"600"} fontSize={"14px"}>
          WHAT'S NEXT?
        </Typography>

        <Typography color={"#323E59"} fontWeight={"500"} fontSize={"14px"}>
          You can add more events via the import page, or go back to the events{" "}
          <br /> page to view, and modify the events.
        </Typography>
        <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
          <ButtonComponent
            title={
              <Typography
                fontSize={"12px"}
                fontWeight={"500"}
                paddingX={"15px"}
                paddingY={"5px"}
              >
                Import More Events
              </Typography>
            }
            background="rgba(0, 0, 0, 0.1)"
            color="black"
            onClick={() => {
              removeFileHandler();
              setPage(0);
            }}
          />
          <ButtonComponent
            title={
              <Typography
                fontSize={"12px"}
                fontWeight={"500"}
                paddingX={"45px"}
              >
                Go To Events
              </Typography>
            }
            onClick={() => {
              doNavigate(eventsRoute);
            }}
          />
        </Stack>
      </Stack>
    </CardContainer>
  );
};

export default CompletedUpload;
