import { CssBaseline, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AppBarComponent from "../components/app-bar-component";
import AboutPage from "../pages/about-page";
import ActivityTypesPage from "../pages/activity-types-page";
import AddMembersPage from "../pages/add-members-page";
import AddQualPage from "../pages/add-qual-page";
import ClubDetailsPage from "../pages/club-details-page";
import DashboardPage from "../pages/dashboard-page";
import EditEventPage from "../pages/edit-event-page";
import EditMemberPage from "../pages/edit-member-page";
import EditOpponentPage from "../pages/edit-opponent-page";
import EditTeamPage from "../pages/edit-team-page";
import EventTypesPage from "../pages/event-types-page";
import EventsPage from "../pages/events-page";
import LeaderboardPage from "../pages/leaderboard-page";
import MembersPage from "../pages/members-page";
import NoClubsPage from "../pages/no-clubs-page";
import NotFoundPage from "../pages/not-found-page";
import NotificationPage from "../pages/notification-page";
import OnboardingPage from "../pages/onboarding-page";
import OpponentsPage from "../pages/opponents-page";
import QualificationsPage from "../pages/qualifications-page";
import SettingsPage from "../pages/settings-page";
import TeamsPage from "../pages/teams-page";
import { useApiUtilsContext } from "../providers/api-utils-provider";
import { useCustomKeycloak } from "../providers/custom-keycloak-provider";
import { setCurrentClubMemberQuals, setUserClubs, setUserDetails, updateLoadingData } from "../redux/app-slice";
import { selectLoadingData } from "../redux/selectors";
import theme from "../styles/theme";
import { apiPathGetMemberClubs, apiPathGetMemberQuals, apiPathGetUser } from "../utils/endpoint-paths";
import { headKeyIn } from "../utils/functionals";
import { importRoute } from "../utils/page-routes";
import ImportPage from "../pages/import-page";
import { aboutRoute, activityTypesRoute, addMembersRoute, clubDetailsRoute, dashboardRoute, editEventRoute, editMembersRoute, editOpponentRoute, editTeamRoute, eventTypesRoute, eventsRoute, leaderboardRoute, membersRoute, notificationsRoute, onboardingRoute, opponentsRoute, qualRoute, qualificationsRoute, settingsRoute, teamsRoute } from "../utils/page-routes";
import { useHasNoClubs, useIsAdmin } from "../utils/user-hooks";
import LoadingPage from "./loading-page";


const AdminOnlyElement = ({ children }) => {
  const isAdmin = useIsAdmin()
  if (isAdmin) {
    return children
  } else {
    return <Navigate to={dashboardRoute} replace />
  }
}

const App = () => {


  const dispatch = useDispatch()

  const dispatchUserDetails = (details) => dispatch(setUserDetails(details))
  const dispatchUserClubs = (clubs) => dispatch(setUserClubs(clubs))
  const dispatchMemberQual = (qual) => dispatch(setCurrentClubMemberQuals(qual))
  const dispatchDoneLoading = () => dispatch(updateLoadingData(false))


  const loadingDetails = useSelector(selectLoadingData)



  const { keycloak, initialised } = useCustomKeycloak()
  const { setToken, isTokenSet, doGet, generateEndpoint } = useApiUtilsContext()

  useEffect(() => {

    const getDetails = async () => {
      const uuid = (await keycloak.loadUserInfo())?.sub
      const getUserEndpoint = generateEndpoint(apiPathGetUser(uuid))
      const getUserClubsEndpoint = generateEndpoint(apiPathGetMemberClubs(uuid))

      const userResponse = await doGet({ endpoint: getUserEndpoint })
      dispatchUserDetails(userResponse?.data)

      const clubsResponse = await doGet({ endpoint: getUserClubsEndpoint })

      const memberId = clubsResponse?.data[headKeyIn(clubsResponse?.data)]?.id
      const getQualificationsEndPoint = generateEndpoint(apiPathGetMemberQuals(memberId))

      const qualResponse = await doGet({ endpoint: getQualificationsEndPoint });
      dispatchMemberQual(qualResponse?.data)
      dispatchUserClubs(clubsResponse?.data)

      dispatchDoneLoading()

    }


    if (isTokenSet()) {
      getDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak.token, isTokenSet])


  useEffect(() => {

    const initKeycloak = async () => {

      if (initialised) {
        if (keycloak) {

          if (!keycloak.authenticated) {
            console.log("Prompting login", keycloak.token)
            await keycloak.login(
              //{prompt: !keycloak?.token ? "none" : "login"}
            )
          }
          setToken(keycloak.token)
        }
      }
    }
    initKeycloak()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak, initialised, setToken])


  const hasNoClubs = useHasNoClubs()


  if (!initialised) {
    return <LoadingPage message="Loading..." />
  }

  if (!keycloak.authenticated || !isTokenSet()) {
    return <LoadingPage message="Authenticating..." />
  }








  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <AppBarComponent />
        {/** TODO: ERROR HANDLING */}
        {loadingDetails && <LoadingPage message="Loading..." />}
        {hasNoClubs && <NoClubsPage />}
        {(!hasNoClubs && !loadingDetails) && <Routes>
          <Route path={dashboardRoute} element={<DashboardPage />} />
          <Route path={teamsRoute} element={<TeamsPage />} />
          <Route path={membersRoute} element={<AdminOnlyElement><MembersPage /></AdminOnlyElement>} />
          <Route path={addMembersRoute} element={<AddMembersPage />} />
          <Route path={clubDetailsRoute} element={<ClubDetailsPage />} />
          <Route path={editTeamRoute} element={<EditTeamPage />} />
          <Route path={editMembersRoute} element={<EditMemberPage />} />
          <Route path={qualificationsRoute} element={<AdminOnlyElement><QualificationsPage /></AdminOnlyElement>} />
          <Route path={activityTypesRoute} element={<AdminOnlyElement><ActivityTypesPage /></AdminOnlyElement>} />
          <Route path={eventTypesRoute} element={<AdminOnlyElement><EventTypesPage /></AdminOnlyElement>} />
          <Route path={opponentsRoute} element={<AdminOnlyElement><OpponentsPage /></AdminOnlyElement>} />
          <Route path={editOpponentRoute} element={<EditOpponentPage />} />
          <Route path={notificationsRoute} element={<NotificationPage />} />
          <Route path={eventsRoute} element={<EventsPage />} />
          <Route path={editEventRoute} element={<EditEventPage />} />
          <Route path={onboardingRoute} element={<OnboardingPage />} />
          <Route path={aboutRoute} element={<AboutPage />} />
          <Route path={leaderboardRoute} element={<LeaderboardPage />} />
          <Route path={qualRoute} element={<AddQualPage/>}/>
          <Route path={importRoute} element={<ImportPage />}/>
          <Route path={qualRoute} element={<AddQualPage />} />
          <Route path={settingsRoute} element={<SettingsPage />}></Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        }
      </BrowserRouter>
    </ThemeProvider>

  );
}

export default App;
