import { Event } from "@mui/icons-material"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { selectActivityFromCache, selectEventFromCache, selectUserFirstName } from "../../../redux/selectors"
import { greyTextColor } from "../../../styles/style-constants"
import { NotificationEventDetailsList, NotificationListItemBase, NotificationPageBase } from "../notification-type-base"
import { Typography } from "@mui/material"


const getBodyOpener = (name) => `Hey ${name}, `


const ListItem = ({
  notification,
  markAsRead,
  markAsUnread,
  addEventRequest,
  addActivityRequest,
  setSelectedNotification,
  selected
}) => {

  const name = useSelector(selectUserFirstName)
  const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))
  const delegateeName = notification?.metadata?.volunteer_name ?? "..."

  useEffect(() => {
    addEventRequest(notification?.metadata?.event_id)
    addActivityRequest(notification?.metadata?.activity_id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <NotificationListItemBase
    setSelectedNotification={setSelectedNotification}
    selected={selected}
    notification={notification}
    getBodyText={() => getBodyOpener(name) + `A change has been made to the following event:`}
    markAsRead={markAsRead}
    markAsUnread={markAsUnread}
    image={<Event fontSize="large" htmlColor={greyTextColor} />}
    getBody={() => <NotificationEventDetailsList
      event={event}
    />}
  />

}

const Page = ({ notification, refreshNotifications }) => {
  const name = useSelector(selectUserFirstName)
  const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))
  const activity = useSelector(selectActivityFromCache(notification?.metadata?.activity_id))

  return (
    <>
      <NotificationPageBase
        notification={notification}
        image={<Event fontSize="large" htmlColor={greyTextColor} />}
        getBodyOpener={() => getBodyOpener(name)}
        getBodyText={() => `A change has been made to the following event:`}
        getBody={() => (
          <NotificationEventDetailsList
            event={event}
            activity={activity}
            showLocation
            showTime
          />
        )}
        getFooter={() => {
          return <Typography>{notification.metadata.message}</Typography>
        }}
      />
    </>
  );

}

const NotificationEventChanged = {
  ListItem,
  Page
}

export default NotificationEventChanged