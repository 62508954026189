import { DeleteOutline } from "@mui/icons-material"
import CloseIcon from '@mui/icons-material/Close'
import { Box, Stack, Typography } from "@mui/material"
import { equals, find, head, map, reject, update } from "ramda"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import BasePageComponent from "../../components/base-page-component"
import ButtonComponent from "../../components/button-component"
import SaveCancelAddStack from "../../components/buttons/save-cancel-add-stack"
import CardContainer from "../../components/card-container"
import BaseDialog from "../../components/dialog-component"
import FloatFormField from "../../components/fields/float-form-field"
import FormHeader from "../../components/form-header-component"
import FormTableComponent from "../../components/form-table-component"
import ObjectListComponent from "../../components/object-list-component"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { selectCurrentClubId } from "../../redux/selectors"
import { darkBlueButtonColor, redButtonColor } from "../../styles/style-constants"
import { newFormEntityId } from "../../utils/constants"
import { apiPathCreateActivityType, apiPathDeleteActivityType, apiPathGetActivityType, apiPathGetActivityTypes, apiPathGetQualificationTypeList } from "../../utils/endpoint-paths"
import { isNullOrUndefinedOrEmpty } from "../../utils/helper-functions/is-null-or-undefined-or-empty"
import useDebounce from "../../utils/use-debounce"

const ActivityTypesPage = () => {

    const [activityList, setActivityList] = useState([])
    const [activityTypeInput, setActivityTypeInput] = useState("")
    const [activityTypeSearch, setActivityTypeSearch] = useState("")

    const currentClubId = useSelector(selectCurrentClubId)

    const { generateEndpoint, doGet, doPost, doPut, uploadPfp, doDelete } = useApiUtilsContext()

    const [searchParams, setSearchParams] = useSearchParams()

    const currentActivityId = searchParams.get("target") ?? undefined
    const setCurrentActivityId = (id) => setSearchParams({ target: id })

    const getActivitiesEndpoint = generateEndpoint(apiPathGetActivityTypes(currentClubId))
    const createActivityEndpoint = generateEndpoint(apiPathCreateActivityType(currentClubId))
    const updateActivityEndpoint = generateEndpoint(apiPathGetActivityType(currentActivityId))
    const getQualsListEndpoint = generateEndpoint(apiPathGetQualificationTypeList(currentClubId))

    const [loading, setLoading] = useState(false)


    const mapQuals = (qual) => {
        return {
            label: qual?.details?.name,
            value: qual?.id
        }
    }

    const qualSelected = (qual) => find((q) => equals(q?.id)(qual?.id))(currentActivityQuals)

    const isCreatingNew = currentActivityId === newFormEntityId
    const disabled = currentActivityId === undefined

    const [currentActivityName, setCurrentActivityName] = useState()
    const [currentActivityDescription, setCurrentActivityDescription] = useState()
    const [currentActivityMultiplier, setCurrentActivityMultiplier] = useState()
    const [currentActivityQuals, setCurrentActivitiyQuals] = useState([])
    const [currentActivityImageId, setCurrentActivityImageId] = useState()
    const [imageFormData, setImageFormData] = useState()

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const [allQuals, setAllQuals] = useState([])

    const mappedQuals = map(mapQuals)(reject(qualSelected)(allQuals))

    const currentActivityIndex = activityList.findIndex((activity) => `${activity.id}` === `${currentActivityId}`);

    const getQualById = id => find((qual) => equals(qual?.id)(id))(allQuals)

    const updateCurrentActivity = (newActivity) => {
        //update the current details to new details
        setActivityList((prev) => update(currentActivityIndex, newActivity, prev));
        setCurrentActivityId(newActivity?.id);
    };

    const addNewActivity = () => {
        setCurrentActivityId(newFormEntityId);
        setActivityList((prev) => [
            { id: newFormEntityId, multiplier: 1.0 },
            ...prev
        ])
    }

    const onSubmit = async () => {
        setLoading(true)
        try {

            let pictureId = currentActivityImageId
            if (imageFormData) {
                pictureId = await uploadPfp(imageFormData)
            }

            const body = {
                details: {
                    name: currentActivityName,
                    description: currentActivityDescription,
                    pictureId
                },
                multiplier: Number.parseFloat(currentActivityMultiplier ?? "1.0"),
                requiredQualifications: currentActivityQuals
            }

            if (isCreatingNew) {


                const response = await doPost({
                    endpoint: createActivityEndpoint,
                    body: body
                });

                const newActivityResponse = await doGet({
                    endpoint: response?.headers?.location
                });

                //Set new stuff
                updateCurrentActivity(newActivityResponse.data);
            } else {

                await doPut({
                    endpoint: updateActivityEndpoint,
                    body: body
                });
                //Set new stuff
                updateCurrentActivity({ id: currentActivityId, ...body });
            }
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    const onCancel = () => {
        if (isCreatingNew) {
            const newActivities = reject((activity) => equals(activity?.id)(newFormEntityId))(activityList)
            setActivityList(newActivities)
            setCurrentActivityId(head(newActivities)?.id)
        } else {
            setCurrentActivityName(activityList[currentActivityIndex]?.details?.name)
            setCurrentActivityDescription(activityList[currentActivityIndex]?.details?.description)
            setCurrentActivityMultiplier(activityList[currentActivityIndex]?.multiplier)
            setCurrentActivitiyQuals(activityList[currentActivityIndex]?.requiredQualifications ?? [])
            setCurrentActivityImageId(activityList[currentActivityIndex]?.details?.pictureId ?? null)
        }
    }

    const onAddQualification = (qual) => {
        const { value: qualId } = qual
        const qualification = getQualById(qualId)
        setCurrentActivitiyQuals((prev) => [...prev, qualification])
    }

    const onRemoveQualification = (qual) => {
        const { id: qualId } = qual
        setCurrentActivitiyQuals((prev) => reject((q) => equals(q?.id)(qualId))(prev))
    }

    useEffect(() => {
        if (currentActivityId) {
            setCurrentActivityName(activityList[currentActivityIndex]?.details?.name)
            setCurrentActivityDescription(activityList[currentActivityIndex]?.details?.description)
            setCurrentActivityMultiplier(activityList[currentActivityIndex]?.multiplier)
            setCurrentActivitiyQuals(activityList[currentActivityIndex]?.requiredQualifications ?? [])
            setCurrentActivityImageId(activityList[currentActivityIndex]?.details?.pictureId)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentActivityId])

    useDebounce(() => setActivityTypeSearch(activityTypeInput), 500, [activityTypeInput])

    useEffect(() => {
        const getActivitiesAndQuals = async () => {

            const queryParams = {
                search_text: activityTypeSearch?.length > 0 ? activityTypeSearch : undefined
            }

            const activityResponse = await doGet({ endpoint: getActivitiesEndpoint, queryParams })
            setActivityList(activityResponse?.data ?? [])

            const qualResponse = await doGet({ endpoint: getQualsListEndpoint })
            setAllQuals(qualResponse?.data ?? [])



        }


        if (currentClubId) {
            getActivitiesAndQuals()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentClubId, activityTypeSearch])

    const onDelete = async () => {
        const deleteEndpoint = generateEndpoint(apiPathDeleteActivityType(currentActivityId))
        try {
            setIsDeleteLoading(true);
            await doDelete({ endpoint: deleteEndpoint });
            setIsDeleteLoading(false);
            setOpenDeleteDialog(false);
            setActivityList((prev) => {
                const newActivityList = reject((activity) => {
                    return equals(activity?.id)(parseInt(currentActivityId))
                }, prev);
                return newActivityList;
            });
            setCurrentActivityId(undefined);
            setSearchParams({});
        } catch (error) {
            setIsDeleteLoading(false)
            console.error(error)
        }

    }

    return <BasePageComponent
        snackbarLoading={loading}
        pageTitle={"Activity Types"}
        marginRight="60px"
        inlineContent={<SaveCancelAddStack
            addLabel="Add New Activity Type"
            addAction={addNewActivity}
            saveAction={onSubmit}
            cancelAction={onCancel}
            deleteComponent={
                <ButtonComponent
                    title={"Delete Activity Type"}
                    icon={<DeleteOutline />}
                    background={darkBlueButtonColor}
                    onClick={() => setOpenDeleteDialog(true)}
                    disabled={
                        disabled ||
                        isCreatingNew ||
                        isNullOrUndefinedOrEmpty(currentActivityId) ||
                        isNullOrUndefinedOrEmpty(currentActivityDescription)
                    }
                />
            }
            disableCancel={disabled}
            disableSave={disabled ||
                isNullOrUndefinedOrEmpty(currentActivityName) ||
                isNullOrUndefinedOrEmpty(currentActivityDescription)
            }
            disableAdd={currentActivityId === newFormEntityId}
        />}
    > <DeleteActivityTypeDialog
            openDeleteDialog={openDeleteDialog}
            setOpenDeleteDialog={setOpenDeleteDialog}
            handleDelete={onDelete}
            isDeleteLoading={isDeleteLoading}
        />
        <Stack direction="row" spacing={5} padding={5}>
            <ObjectListComponent
                title={"Activity Type"}
                searchPlaceholder={"Search for Activity Type..."}
                items={activityList}
                selectedKey={currentActivityId}
                onSelectItem={(item) => setCurrentActivityId(item?.id)}
                getItemName={(activity) => activity?.details?.name ?? "New Activity Type"}
                searchValue={activityTypeInput}
                setSearchValue={setActivityTypeInput}
            />

            <CardContainer padding={"20px 30px 50px 30px"}>
                <FormHeader
                    label={"Activity Type Name"}
                    title={currentActivityName ?? ""}
                    description={currentActivityDescription ?? ""}
                    setTitle={setCurrentActivityName}
                    setDescription={setCurrentActivityDescription}
                    disabled={disabled}
                    minioId={currentActivityImageId}
                    setFormData={setImageFormData}
                    setMinioId={setCurrentActivityImageId}
                    mandatory
                />
                <Box marginTop={5} />
                <FloatFormField
                    label={"Multiplier"}
                    value={currentActivityMultiplier}
                    setValue={setCurrentActivityMultiplier}
                    disabled={disabled}
                />

                <FormTableComponent
                    title="Qualifications required"
                    columns={[
                        { title: "Qualification Type", getValue: (row) => row?.details?.name },
                        { title: "Description", getValue: (row) => row?.details?.description }
                    ]}
                    disabled={disabled}
                    items={currentActivityQuals ?? []}
                    onAdd={onAddQualification}
                    onRemove={onRemoveQualification}
                    searchItems={mappedQuals}
                />

            </CardContainer>



        </Stack>


    </BasePageComponent>


}

export const DeleteActivityTypeDialog = ({
    openDeleteDialog,
    setOpenDeleteDialog,
    handleDelete,
    isDeleteLoading,
}) => {
    return (
        <BaseDialog
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
            title={"Delete Activity Type?"}
            titleProps={{ textAlign: "center", fontSize: "24px" }}
            content={
                <Stack direction={"column"} alignContent={"center"} gap={"10px"}>
                    <Typography
                        color={"#3C3352"}
                        letterSpacing={"2px"}
                        fontSize={"14px"}
                        fontWeight={"600"}
                        textAlign={"center"}
                    >
                        Are you sure you want to delete?
                    </Typography>
                    <Typography
                        color={"#3C3352"}
                        letterSpacing={"2px"}
                        fontSize={"14px"}
                        fontWeight={"600"}
                        textAlign={"center"}
                    >
                        (Please note, this action cannot be reversed)
                    </Typography>
                </Stack>
            }
            actionProps={{ sx: { justifyContent: "center", marginBottom: "20px" } }}
            paperProps={{
                sx: { border: "1px solid rgba(50, 62, 89, 1)", paddingX: "30px" },
            }}
            actions={
                <Stack direction={"row"} gap={"25px"}>
                    <ButtonComponent
                        title={"Delete"}
                        icon={<DeleteOutline fontSize="small" />}
                        background={darkBlueButtonColor}
                        onClick={handleDelete}
                        disabled={isDeleteLoading}
                    />
                    <ButtonComponent
                        disabled={isDeleteLoading}
                        title={"Cancel"}
                        icon={<CloseIcon fontSize="small" />}
                        background={redButtonColor}
                        onClick={() => setOpenDeleteDialog(false)}
                    />
                </Stack>
            }
        />
    );
};


export default ActivityTypesPage