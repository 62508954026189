

export const newFormEntityId = "___NEW___"

export const memberTypeAdmin = "ADMIN"
export const memberTypeClubMember = "CLUB_MEMBER"
export const memberTypeCommunityMember = "COM_MEMBER"


export const statusVacant = "VACANT"
export const statusAssigned = "ASSIGNED"
export const statusAccepted = "ACCEPTED"
export const statusActive = "ACTIVE"
export const statusPending = "PENDING"
export const statusInactive = "INACTIVE"

export const emailPending = "EMAIL_PENDING"
export const attendeeStatusDeclined = "DECLINED"
export const attendeeStatusUnconfirmed = "UNCONFIRMED"
export const attendeeStatusConfirmed = "CONFIRMED"

export const statusList = [
    statusVacant,
    statusAssigned,
    statusAccepted
]

export const memberStatusLabels = { "ACTIVE": "Active", "INACTIVE": "Inactive", "EMAIL_PENDING": "Invitation Sent", "EMAIL_EXPIRED": "Invitation Expired" }

export const memberTypeDropdownOptions = [
    {value: memberTypeAdmin, label: "Admin"},
    {value: memberTypeClubMember, label:"Club Member"},
    {value: memberTypeCommunityMember, label:"Community Member"}
]


// Data Grid Constants
export const invalidCell = "INVALID"
export const templateHeaders = [
  "event_name",
  "date",
  "start",
  "end",
  "type",
  "team",
  "opponent",
  "homeOrAway",
  "locationDescription",
  "description",
];

export const MAX_EVENT_NAME_LENGTH = 50;
export const MAX_DESC_LENGTH = 100;

