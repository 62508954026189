
export const apiPathPostClub = "/clubs"
export const apiPathClubsList = "/clubs/list"
export const apiPathGetClub = (clubId) => `/clubs/${clubId}`

export const apiPathGetMemberClubs = (uuid) => `/member/list/${uuid}`
export const apiPathGetClubMember = (clubId, personId) => `/member/${clubId}/${personId}`

export const apiPathClubMembers = (clubId) => `/clubs/${clubId}/members`
export const apiPathGetClubMemberList = (clubId) => `/member/${clubId}`
export const apiPathAddClubMembers = (clubId) => `/user/${clubId}/add/multiple`
export const apiPathArchiveClubMember = (memberId) => `/member/${memberId}/archive`
export const apiPathEnableClubMember = (memberId) => `/member/${memberId}/enable`

export const apiPathMailResend = (clubId, memberId) => `/member/${clubId}/mail/resend/${memberId}`

export const apiPathGetMemberTeams = (memberId) => `/member/${memberId}/teams`

export const apiPathAddTeamMembersMultiple = (teamId) => `teams/${teamId}/members/add/multiple`

export const apiPathUserList = "/user/list"
export const apiPathUserRegister = "/user/register"
export const apiPathGetUser = (uuid) => `/user/${uuid}`
export const apiPathPutUser = (uuid) => `user/${uuid}`

export const apiPathClubTeamsList = (clubId) => `/teams/club/${clubId}`

export const apiPathCreateTeam = (clubId) => `/teams/${clubId}/create` 
export const apiPathGetTeam = (teamId) => `/teams/${teamId}`
export const apiPathDeleteTeam = (teamId) => `/teams/${teamId}`

export const apiPathGetQualificationTypeList = (clubId) => `/type/qualification/list/${clubId}`
export const apiPathCreateQualificationType = (clubId) => `/type/qualification/${clubId}/create`
export const apiPathGetQualificationType = (clubId, qualId) => `/type/qualification/${clubId}/${qualId}`
export const apiPathPutQualificationType = (clubId, qualId) => `/type/qualification/${clubId}/${qualId}`
export const apiPathDeleteQualificationType = (qualId) => `/type/qualification/${qualId}/archive`

export const apiPathGetActivityTypes = (clubId) => `/type/activity/list/${clubId}`
export const apiPathCreateActivityType = (clubId) => `/type/activity/${clubId}/create`
export const apiPathGetActivityType = (activityId) => `/type/activity/${activityId}`
export const apiPathDeleteActivityType = (activityId) => `/type/activity/${activityId}`

export const apiPathGetEventTypes = (clubId) => `/type/event/${clubId}/list`
export const apiPathCreateEventType = (clubId) => `/type/event/${clubId}/create`
export const apiPathGetEventType = (activityId) => `/type/event/${activityId}`
export const apiPathDeleteEventType = (eventTypeId) => `/type/event/${eventTypeId}`


export const apiPathCreateEvent = (clubId) => `/event/${clubId}/create`
export const apiPathUpdateEvent = (eventId) => `/event/${eventId}/update`
export const apiPathEventList = "/event/list" 
export const apiPathGetClubEvents = (clubId)=> `/clubs/${clubId}/events`

export const apiPathGetEvent = (eventId) => `/event/${eventId}/details`
export const apiPathGetActivity = (activityId) => `/activity/${activityId}/details`

export const apiPathUploadFile = bucket => `/media/upload/${bucket}`
export const apiPathGetFile = (bucket, fileId) => `/media/fetch/${bucket}/${fileId}`


export const apiPathFirebase = (personId) => `/notifications/${personId}/register`

export const apiPathResetPassword = (uuid) => `/user/${uuid}/reset-password`

export const apiPathGetDashboard = (clubId) => `dashboard/${clubId}`
export const apiPathGetAdminDashboard = (clubId) => `dashboard/admin/${clubId}`
export const apiPathGetMemberDashboard = (personId) => `dashboard/member/${personId}`

export const apiPathGetUpcomingEvents = (memberId) => `event/${memberId}/upcoming`

export const apiPathGetMemberQuals = (memberId) => `qualification/${memberId}/list`

export const apiPathAddBulkQual = (memberId) => `qualification/${memberId}/upload/bulk`

export const apiPathOnboard = (memberId) => `member/${memberId}/onboard`

export const apiPathGetOpponentList = (clubId) => `clubs/opponent/${clubId}/list`
export const apiPathGetOpponent = opponentId => `clubs/opponent/${opponentId}`
export const apiPathUpdateOpponent = opponentId => `clubs/opponent/${opponentId}`
export const apiPathPostOpponent = (clubId) => `clubs/opponent/${clubId}/create`
export const apiPathDeleteOpponent = opponentId => `clubs/opponent/${opponentId}`

export const apiPathNotificationList = (personId) => `notifications/${personId}`
export const apiPathReadNotification = (personId) => `notifications/${personId}/read`
export const apiPathUnreadNotification = (personId) => `notifications/${personId}/unread`

export const apiPathLeaderboard = (clubId) => `clubs/${clubId}/leaderboard`

export const apiPathDeleteQual = (qualId) => `qualification/${qualId}`

export const apiPathConfirmAttendee = (eventId, personId) => `event/${eventId}/${personId}/confirm`
export const apiPathDeclineAttendee = (eventId, personId) => `event/${eventId}/${personId}/decline`
export const apiPathUnconfirmAttende = (eventId, personId) => `event/${eventId}/${personId}/unconfirm`

export const apiPathVolunteerActivity = (activityId, personId) => `activity/${activityId}/${personId}/volunteer`

export const apiPathAcceptDelegation = (activityId) => `activity/${activityId}/accept`
export const apiPathDeclineDelegation = (activityId) => `activity/${activityId}/decline`
export const apiPathUnconfirmDelegation = (activityId) => `activity/${activityId}/unconfirm`

export const apiPathDeleteEvent = (clubId, eventId) => `event/${clubId}/${eventId}`

export const apiPathDelegateAcitivty = (activityId, delegateeId) => `activity/${activityId}/delegate/${delegateeId}`

export const apiPathBulkUpload = (clubId) => `event/${clubId}/create/bulk`
export const apiPathSettings = "/user-settings"

//Unsure what this does, jsut took a guess
// export const apiPathLinkQualification = (clubId, uuid, qualId) => `/qualification/${clubId}/${uuid}/${qualId}/submit`