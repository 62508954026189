import { FormControlLabel, Switch, Typography } from '@mui/material'
import React from 'react'
import { greyTextColor } from '../../styles/style-constants'

const SwitchTile = ({ value, label, onChange, style = { padding: "5px" } }) => {
  return (
    <FormControlLabel
      style={style}
      checked={value}
      onChange={onChange}
      control={<Switch
        inputProps={{ 'aria-label': 'controlled' }} />} label={
          <Typography letterSpacing={"1.5px"} fontWeight={600} color={greyTextColor} fontSize={"12px"}>
            {label}
          </Typography>} />
  )
}


export default SwitchTile
