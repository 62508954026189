import dayjs from "dayjs"
import { blueButtonColor } from "../../styles/style-constants"
import { Grid, Stack } from "@mui/material"
import { newFormEntityId, statusAccepted, statusAssigned, statusVacant } from "../../utils/constants"
import StatusCircleComponent from "../../components/status-circle-component"
import { isUndefined } from "../../utils/helper-functions/is-null-or-undefined-or-empty"
import { editEventRoute } from "../../utils/page-routes"
import { useIsAdmin } from "../../utils/user-hooks"
import { all, values } from "ramda"
import { useState } from "react"
import useDoNewTab from "../../utils/do-new-tab"



const DayBox = ({
    date,
    selectedDate,
    noMonthHighlight = false,
    counts,
    onClick,
    onDotClick
}) => {

    const doNewTab = useDoNewTab()

    const [hovered, setHovered] = useState(false)
    const [dotHovered, setDotHovered] = useState(false)

    const admin = useIsAdmin()

    const noEvents = isUndefined(counts) ? true : all((value) => value === 0)(values(counts))


    const inMonth = date.isSame(selectedDate, 'month')
    const isStartOfMonth = date.isSame(date.startOf('month'))
    const isToday = dayjs().isSame(date, "day")
    const isSelected = selectedDate.isSame(date, "day")
    const backgroundColor = noMonthHighlight || inMonth ? "transparent" : "#f6f6f6"


    const doOnClick = (date) => {

        if (admin) {
            doNewTab(editEventRoute, {target: newFormEntityId, date: date.toISOString()})
        } else {
            onClick(date)

        }


    }


    return <Grid
        item
        width={"100%"}
        xs={1}
        onClick={() => {
            if (onClick) {
                if (dotHovered && onDotClick) {
                    onDotClick(date)
                } else {
                    doOnClick(date)
                }
                
            }
        }}
        onMouseEnter={() => {
            setHovered(true)
        }}
        onMouseLeave={() => {
            setHovered(false)
        }}
        sx={{
            position: "relative",
            background: backgroundColor,
            textAlign: 'center',
            border: "solid 1px #F1F1F1",
            borderColor: isSelected ? '#2c3e5055' : "#F1F1F1",
            textTransform: "uppercase",
            paddingTop: "10px",
            color: isToday ? blueButtonColor : (noMonthHighlight || inMonth ? "#595959" : "#787878"),
            fontSize: "20px",
            minHeight: "100px",
            fontWeight: 500,
            ":hover": {
                background: onClick && !dotHovered ? "#D1D1D1" : backgroundColor,
                cursor: onClick && "pointer"
            }
        }}

    >
        <div style={{
            position: "absolute",
            textAlign: "center",
            width: "100%",
            height: "100%",
            display: "flex",
            fontSize: "30pt",
            flexDirection: "column",
            justifyContent: "center",
            visibility: hovered && noEvents && !dotHovered ? "visible" : "hidden"
        }}>
            +
        </div>
        <Stack direction="column" height={"100%"} justifyContent={"space-between"}>
            {isStartOfMonth ? date.format("MMM") : ""} {date.format("DD")}

            {counts &&
                <Stack direction={"row"} spacing={"2px"} justifyContent={"space-evenly"} marginBottom={2}>
                    {counts[statusAccepted] > 0 && <StatusCircleComponent status={statusAccepted} count={counts[statusAccepted]}
                        onMouseEnter={() => {setDotHovered(true)}}
                        onMouseLeave={() => {setDotHovered(false)}}
                    />
                    }
                    {counts[statusAssigned] > 0 && <StatusCircleComponent status={statusAssigned} count={counts[statusAssigned]}
                        onMouseEnter={() => {setDotHovered(true)}}
                        onMouseLeave={() => {setDotHovered(false)}}
                    />}
                    {counts[statusVacant] > 0 && <StatusCircleComponent status={statusVacant} count={counts[statusVacant]}
                        onMouseEnter={() => {setDotHovered(true)}}
                        onMouseLeave={() => {setDotHovered(false)}}
                    />}

                </Stack>
            }
        </Stack>


    </Grid>
}

export default DayBox